
  /* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from '@heroicons/react/solid'

export default function Example() {
  return (
    <div className="bg-naviBlueDark p-16">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">Try Navi now for free</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-indigo-200">
        Available for iPhone, iPad and Mac.
        </p>
        <a href='https://apps.apple.com/us/app/navi-subtitles-translation/id1573261774' className="mt-8 w-full inline-flex items-center justify-center text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto"> 
              <img src='images/appstore.png' className='h-16' />
                </a>
       
      </div>
    </div>
  )
}