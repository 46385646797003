/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline'

export default function Example() {
  return (
    <div className="relative bg-gray-50">
      <Popover className="relative bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                 <h1 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-3xl lg:text-3xl xl:text-3xl">
                  <span className="block text-blue-600 xl:inline">Navi</span>
                </h1>
              </a>
            </div>
          </div>
        </div>
      </Popover>

      <main className="lg:relative">
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl lg:text-5xl xl:text-5xl">
              <span className="block xl:inline">Captions & Live Translation</span> 
              <br/>
              <span className="block xl:inline">for FaceTime</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            Get it for free on iPhone, iPad and Mac
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div>
                
              <a href='https://apps.apple.com/us/app/navi-subtitles-translation/id1573261774'> 
              <img src='images/appstore.png' className='h-16' />
                </a>
              </div>
            </div>
          </div>

        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src="/images/header.png"
            alt=""
          />
        </div>
      </main>
    </div>
  )
}
