  export default function Footer() {
	return (
	  <footer className="bg-white">
		<div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">

		  <p className="mt-8 text-center text-base text-gray-600">&copy; 2021 Good Snooze. Made by <a href="https://www.twitter.com/jordibruin">Jordi Bruin</a> in Home Quarantine.</p>
		</div>
	  </footer>
	)
  }
  
  