  
  export default function Scanner() {
	return (
	  <div className="relative bg-gradient-to-b from-naviBlue to-naviBlueDark py-16 sm:py-24 lg:py-32">
		<div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
		  <h2 className="text-base font-semibold tracking-wider text-white uppercase">See it in action</h2>
		  <p className="mt-2 text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
		Navi adds subtitles to your Facetime calls
		  </p>
		  <p className="mt-5 max-w-prose mx-auto text-xl text-white">
		  </p>
		  <div class="pt-6">
			<div class="aspect-w-16 aspect-h-9">
  				<iframe src="https://www.youtube.com/embed/lmBwn-THjtE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
			</div>
		  </div>
		</div>
	  </div>
	)
  }

